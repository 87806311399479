<template>
    <v-row class='px-2'>
        <v-col class=12 md=5>
            <v-alert dense type="info"  icon="mdi-check-all">
                CKECK LIST
            </v-alert>
            <v-list-item-group
                v-model="checks"
                multiple
            >
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Nombres:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Razón social:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.business_name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Identificaciones:</v-list-item-title>
                            <v-list-item-subtitle 
                                v-for="(identification, x) in dataParty.identifications" 
                                :key=x
                            >
                            {{identification.identification_type}}:  {{identification.id_value}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Direcciones:</v-list-item-title>
                            <v-list-item-subtitle
                                v-for="(direction, x) in dataParty.directions" 
                                :key=x
                            >
                                {{x+1}}) {{direction.direction}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Obligado a llevar contabilidad:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.preferences.obligado_cont ? 'SI' : 'NO'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Contribuyente especial:</v-list-item-title>
                            <v-list-item-subtitle>
                                {{dataParty.preferences.contr_esp ? 'SI' : 'NO'}} {{dataParty.preferences.cod_contr_esp!='' ? '/ '+dataParty.preferences.cod_contr_esp :''}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Regimen rimpe:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.preferences.micro_empresa ? 'SI' : 'NO'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Forma de pago:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.patymentMethod}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Nombre de la cuenta:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.name_on_account}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Nombre de compañia en cuenta:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.company_name_on_account}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Identificiación de compañia en cuenta:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.num_identificacion}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Banco:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.bank}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Tipo de cuenta:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.account_type}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="dataParty.paymentType.default_pay_meth == 'EFT_ACCOUNT'"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Número de cuenta:</v-list-item-title>
                            <v-list-item-subtitle>{{dataParty.paymentType.account_number}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>
            <v-col class='text-center'>
                <div v-if="dataParty.status_id == 'PARTY_TO_VALIDATE'">
                    <v-btn 
                        color='primary' 
                        @click='aprovedSupplier'
                        class='mx-2'
                    >
                        <v-icon>mdi-account-check</v-icon> APROBAR
                    </v-btn>
                    <v-btn 
                        color='error' 
                        @click='rejectSupplier'
                        class='mx-2'
                    >
                        <v-icon>mdi-account-remove</v-icon> RECHAZAR
                    </v-btn>
                </div>
                <v-alert
                    dense
                    v-if="dataParty.status_id == 'PARTY_ENABLED'"
                    type='success'
                > 
                    PROVEEDOR ACTIVADO
                </v-alert>
            </v-col>
        </v-col>
        <v-col class=12 md=7 >
            <v-alert dense type="info" icon="mdi-file-image" >
                ARCHIVOS CARGADOS
            </v-alert>
            <v-expansion-panels  v-model="panel" focusable multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header  active-class="expand_active">
                        <v-icon>mdi-file-find</v-icon> CÉDULA CARGADA
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if=!!fileCedula style="height:400px">
                            <iframe 
                                :src="`data:${fileCedula.mime_type_id};base64,${fileCedula.base64}`" 
                                frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
                                allowfullscreen
                            />
                        </div>
                        <div v-else>
                            <v-alert
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                                dense
                                >
                                No se ha cargado el archivo
                            </v-alert>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-header >
                        <v-icon>mdi-file-find</v-icon> RUC CARGADO
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if=!!fileRuc style="height:400px">
                            <iframe 
                                :src="`data:${fileRuc.mime_type_id};base64,${fileRuc.base64}`" 
                                frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
                                allowfullscreen
                            />
                        </div>
                        <div v-else>
                            <v-alert
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                                dense
                                >
                                No se ha cargado el archivo
                            </v-alert>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-header >
                        <v-icon>mdi-file-find</v-icon> REPRESENTANTE LEGAL CARGADO
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if=!!fileRepLegal style="height:400px">
                            <iframe 
                                :src="`data:${fileRepLegal.mime_type_id};base64,${fileRepLegal.base64}`" 
                                frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
                                allowfullscreen
                            />
                        </div>
                        <div v-else>
                            <v-alert
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                                dense
                                >
                                No se ha cargado el archivo
                            </v-alert>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-header >
                        <v-icon>mdi-file-find</v-icon> CERTIFICADO BANCARIO CARGADO
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if=!!fileCertBank style="height:400px">
                            <iframe 
                                :src="`data:${fileCertBank.mime_type_id};base64,${fileCertBank.base64}`" 
                                frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
                                allowfullscreen
                            />
                        </div>
                        <div v-else>
                            <v-alert
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                                dense
                                >
                                No se ha cargado el archivo
                            </v-alert>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-header >
                        <v-icon>mdi-file-find</v-icon> CONTRATO DE SERVICIOS CARGADO
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if=!!fileContract style="height:400px">
                            <iframe 
                                :src="`data:${fileContract.mime_type_id};base64,${fileContract.base64}`" 
                                frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
                                allowfullscreen
                            />
                        </div>
                        <div v-else>
                            <v-alert
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                                dense
                                >
                                No se ha cargado el archivo
                            </v-alert>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
    
</template>

<script>
import {mapMutations, mapActions, mapState} from 'vuex'
import Vue from 'vue'

export default {
    name:'CheckSupplierCompnent',
    data:()=>({
        panel: '',
        checks:[],
        dataParty:{
            preferences:{},
            paymentType:{} 
        },
        items:{
            cedula: false,
            ruc: false,
        },
        files:[]
    }),
    computed:{

        ...mapState('purchases',['urlFilesParty']),

        ...mapState('master',['paramAlertQuestion','user']),

        fileCedula(){
            return this.files.find(e => e.description == 'cedula')
        },

        fileCertBank(){
            return this.files.find(e => e.description == "cert_bank")
        },

        fileContract(){
            return this.files.find(e => e.description == 'contract')
        },

        fileRepLegal(){
            return this.files.find(e => e.description == 'rep_legal')
        },

        fileRuc(){
            return this.files.find(e => e.description == 'ruc')
        }

    },
    methods:{
        
        ...mapMutations('master',['setTitleToolbar','setUrl']),

        ...mapActions('master',['requestApi','alertNotification']),

        getFilesLoaded(){

            this.setUrl('lista-partys')
            
            this.requestApi({
                method: 'PATCH',
                data :{
                    partyId: this.$route.params.partyId,
                    urlFilesParty: this.urlFilesParty
                }
            }).then(res =>{

                console.log(res)
                this.files = res.data._embedded.lista_partys[0]
                this.dataParty = res.data._embedded.lista_partys[1][0]

                console.log(this.dataParty )
            }).then(()=>{
              
            })

        },

        aprovedSupplier(){
            
            Vue.swal({
                html: "Está seguro de aprobar a este proveedor?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'POST',
                        data :{
                            party_id: this.$route.params.partyId,
                            workEffortId: this.$route.params.workEffortId,
                            status_id:'PARTY_ENABLED',
                            checks: this.checks,
                            typeStore:'approveSupplier'
                        }
                    }).then(res =>{

                        this.alertNotification({param:{html: res.data.res.msg}})
                        this.getFilesLoaded()
			        })

                }
            })
           
        },

        rejectSupplier(){
            
            Vue.swal({
                html: "Está seguro de rechazar los datos de configuración de este proveedor?",
                icon: "warning",
                input: 'textarea',
                inputPlaceholder: 'Escriba un comentario',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion,
                inputValidator: (value) => {
                    if (!value) 
                        return 'Debe escribir un comentario'
                }
            }).then( result => {
                if (result.isConfirmed) {
                    console.log(result)
                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'POST',
                        data :{
                            workEffortId: this.$route.params.workEffortId,
                            party_id: this.$route.params.partyId,
                            role_type_id: 'SUPPLIER_EDIT',
                            status_id: 'PARTY_IN_PROCESS',
                            typeStore: 'rejectSupplier',
                            description: result.value
                        }
                    }).then(res =>{

                        this.alertNotification({param:{html: res.data.res.msg}})
                        this.getFilesLoaded()
			        })

                }
            })

        }

    },
    mounted(){

        this.setTitleToolbar('VERIFICACIÓN DE PROVEEDOR')
        this.getFilesLoaded()
    }
}
</script>
<style  >
    .expand_active{
        background-color: #4caf50 !important;
    }
    .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
        flex: inherit!important;
    }

    .v-alert__icon {
        margin-right: 0px!important;
    }
</style>